import { configureStore } from "@reduxjs/toolkit";
import { adminSlice } from "../adminSlice";
import { mentorSlice } from "../mentorSlice";
import { userSlice } from "../userSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    admin: adminSlice.reducer,
    mentor:mentorSlice.reducer
  },
});
