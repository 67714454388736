import React, { useState } from 'react'
import Button from '../../../components/Button/Button';
import './MentorFeedbackStyle.css';

const MentorFeedbackPreview = () => {
const [tabToggler, setTabToggler] = useState(0);
    return (
      <>
      <div className="headerSmall withButton">
                <p className="HighlightHeading"><span className="parent">Feedback </span>/ Feedback Recieved</p>
                <div className="buttonGroupCalls">
                    <Button className={`greenBgBtn ${tabToggler === 0 && "active"}`} name={'Feedback Recieved'} onClick={() => setTabToggler(0)} />
                    <Button className={`blueBgBtn ${tabToggler === 1 && "active"}`} name={'Given Fedback'} onClick={() => setTabToggler(1)} />
                </div>
            </div>

            <div className="tableContainer FeedbackCallWrapper mt-10">
                <div className={`feedbackPreview ${tabToggler === 0 && "active"}`}>
                    <div className="feedbackData">
                        <div className="mentorContent">
                            <img src="./assets/img/demoUser1.png" alt="" />
                            <div className="mentorDetails">
                                <div className="viewProfile">
                                    <a href="#" onClick={e => e.preventDefault()}>View Profile</a>
                                </div>
                                <p className="mentorName">
                                    Narendra Kumar
                                </p>
                                <p className="mentorDetail mt-5">
                                    Director, KPMG in India
                                </p>
                            </div>
                        </div>
                        <div class="dateAndTime studentSide">
                            <p className="nameData">Meeting Date & Time</p>
                            <div className='fixDiv'>
                                <span class="boxiDetail">Wed, 22 Jan 2023</span>
                                <span class="boxiDetail">11:30 AM</span>
                            </div>
                        </div>
                    </div>
                    <div className="feedbackQuestion">

                        <div className="feedbackBox">
                            <p className="text-1">How was your experience with the mentee?</p>
                            <div className="ratingBarWrap">
                                <div className="ratingBar mt-15">
                                    <span className='active'></span>
                                    <span className='active'></span>
                                    <span className='active'></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ratingBarCount">
                                    <span>0</span>
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                </div>
                            </div>

                        </div>

                        <div className="feedbackBox">
                            <p className="text-1">How was your experience with the mentee?</p>
                            <p className="textAnswer mt-5">Looking for a new job/career switch</p>
                        </div>

                        <div className="feedbackBox">
                            <p className="text-1">Were you able to come up with an action plan for the mentee?</p>
                            <p className="textAnswer mt-5">Yes</p>
                        </div>

                        <div className="feedbackBox">
                            <p className="text-1">How would you rate the mentee’s employability, if you were to hire them for your own team?</p>
                            <div className="ratingBarWrap">
                                <div className="ratingBar mt-15">
                                    <span className='active'></span>
                                    <span className='active'></span>
                                    <span className='active'></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ratingBarCount">
                                    <span>0</span>
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className={`feedbackPreview ${tabToggler === 1 && "active"}`}>
                    {/* <div className="feedbackData">
                        <div className="mentorContent">
                            <img src="./assets/img/demoUser1.png" alt="" />
                            <div className="mentorDetails">
                                <div className="viewProfile">
                                    <a href="#" onClick={e => e.preventDefault()}>View Profile</a>
                                </div>
                                <p className="mentorName">
                                    Narendra Kumar
                                </p>
                                <p className="mentorDetail mt-5">
                                    Director, KPMG in India
                                </p>
                            </div>
                        </div>
                        <div class="dateAndTime studentSide">
                            <p className="nameData">Meeting Date & Time</p>
                            <div className='fixDiv'>
                                <span class="boxiDetail">Wed, 22 Jan 2023</span>
                                <span class="boxiDetail">11:30 AM</span>
                            </div>
                        </div>
                    </div> */}
                    <div className="feedbackQuestion">

                        <div className="feedbackBox">
                            <p className="text-1">How was your experience with the mentee?</p>
                            <div className="ratingBarWrap">
                                <div className="ratingBar mt-15">
                                    <span className='active'></span>
                                    <span className='active'></span>
                                    <span className='active'></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ratingBarCount">
                                    <span>0</span>
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                </div>
                            </div>

                        </div>

                        <div className="feedbackBox">
                            <p className="text-1">Did the call help you get any clarity?</p>
                            <p className="textAnswer mt-5">Yes</p>
                        </div>

                        <div className="feedbackBox">
                            <p className="text-1">Would you like to schedule a call again with the same mentor?</p>
                            <p className="textAnswer mt-5">Yes</p>
                        </div>

                        <div className="feedbackBox">
                            <p className="text-1">Was the mentor able to answer your questions?</p>
                            <p className="textAnswer mt-5">Yes</p>
                        </div>


                    </div>
                </div>
            </div>
      </>
    )
};

export default MentorFeedbackPreview
