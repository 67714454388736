import React from "react";
import Button from "../Button/Button";
import CustomUploadBox from "../customUploadBox/CustomUploadBox";
import InputField from "../InputField/InputField";
// import PrimaryButton from "../primaryButton/PrimaryButton";
import "./UploadImagePopupStyle.css";

// import CustomUploadBox from "./"
const UploadImagePopup = ({ popout, handleClose, value, handleUploadProfile, handleRemove,active, handleDelete,clearRemoveProfile,handleOk, accept }) => {
  return (
    <div className={`popupOuter ${popout ? "active" : ""}`}>
      <div className="uploadImageContainer">
        <div className="popupHeader">
          <p className="popupTitle">Upload Image</p>
          <img className="closeWhite" src="../assets/icons/Close.svg" alt="" onClick={handleClose} />
        </div>
        <div className="popupBody">
          <CustomUploadBox
            label="Upload Image "
            uid={"upload-box2"}
            isImportant={true}
            onChange={handleUploadProfile}
            value={value}
            handleRemove={handleRemove}
            active={active}
            handleDelete={ handleDelete}
            accept={accept}
            
          />
        </div>
        <div className="popupBtnGrp">
        {/* <PrimaryButton classname={"primaryBtn"} children={"OK"} onClick={handleClose} />
          <PrimaryButton classname={"dangerBtn"} children={"Clear"} onClick={handleRemove}/> */}
          <Button onClick={handleClose} className={'blueButton'} name={'OK'}/>
          <Button onClick={clearRemoveProfile} className={'outlineBtn'} name={'Remove Profile'}/>
          {/* <button  onClick={handleClose} >Ok</button>
          <button onClick={handleRemove} >Clear</button> */}
        </div>
      </div>
    </div>
  );
};

export default UploadImagePopup;
