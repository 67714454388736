import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import MentorOverView from "./MentorOverView";
import { useNavigate } from "react-router-dom";
import {
  getMentorDetails,
  mentorSliceData,
  clearState,
  changePassword
} from "../../../reducer/mentorSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader/PageLoader";
import ChangePassPopup from "../../../components/changePassPopup/ChangePassPopup";
import ErrorToast from "../../../components/Toast/ErrorToast";
import SuccessToast from '../../../components/Toast/SuccessToast';

const ViewMentorProfile = () => {
  const navigate = useNavigate();
  const [isActive, setisActive] = useState(false);
  const dispatch = useDispatch();
  const { isFetching, specificMentor, isError, isSuccess, errorMessage } =
    useSelector(mentorSliceData);

  useEffect(() => {
    dispatch(getMentorDetails());
  }, []);
  console.log("MentorDetails", specificMentor);

  // change password

  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const passwordTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: "",
  };
  const [password, setPassword] = useState(passwordTemplate);
  const [passwordValidation, setPasswordValidation] =
    useState(validationTemplate);

  const handlePopupClose = () => {
    setisActive(false);
    setPassword(passwordTemplate);
  };

  const handlePassChange = (e) => {
    setPasswordValidation(validationTemplate);
    const { name, value } = e.target;
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickChangePass = () => {
    if (!validation()) {
      const obj = {
        oldPassword: password.oldPassword,
        newPassword: password.newPassword,
        mentorId :specificMentor.id
      };
      dispatch(changePassword(obj));
    }
  };

  const validation = () => {
    for (const key in password) {
      if (!password[key]) {
        setPasswordValidation((prev) => ({
          ...prev,
          [key]: "Please fill this field",
        }));
        return true;
      }
    }
    if (password.newPassword !== password.confirmPassword) {
      setPasswordValidation((prev) => ({
        ...prev,
        error: "Password & Confirm Password do not match",
      }));
      return true;
    }
    if (password.confirmPassword.length <6) {
        setPasswordValidation((prev) => ({
          ...prev,
          error: "Password should be atleast 6 digit",
        }));
        return true;
      }
    return false;
  };
  
  const handleKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      if (!validation()) {
        const obj = {
          oldPassword: password.oldPassword,
          newPassword: password.newPassword,
          mentorId: specificMentor.id
        };
        dispatch(changePassword(obj));
      }
    }
  };

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
      setPasswordValidation((prev) => ({
        ...prev,
        error: "Password incorrect",
      }));
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setPassword(passwordTemplate);
      setIsShowSuccess(true);
      setSuccessMessage("Password successfully changed!");
      setisActive(false);
      dispatch(clearState());
    }
  }, [isSuccess]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <ChangePassPopup
        isActive={isActive}
        handleClose={handlePopupClose}
        value={password}
        onChange={handlePassChange}
        onClick={handleClickChangePass}
        validation={passwordValidation}
        onKeyPress={handleKeyPressSubmit}
      />
      <div className="headerSmall withButton">
        <p className="HighlightHeading">My Profile</p>
        <Button
          className={"imgButton GreenSlotBorder"}
          src={"/assets/icons/user-edit.svg"}
          name={"Edit Profile"}
          onClick={() => {
            navigate("/editMentor");
          }}
        />
      </div>

      <div className="mentorProfileContainer mt-10">
        <div className="mentorProfile">
          <img
            src={
              specificMentor?.profilePicUrl
                ? specificMentor.profilePicUrl
                : "./assets/img/demoUser.png"
            }
            alt=""
            className="mentorImg"
          />
          <div className="mentorDetailsProfile">
            <p className="mentorNameHeading">
              {specificMentor?.firstName ? specificMentor?.firstName : "---"}{" "}
              {specificMentor?.lastName ? specificMentor?.lastName : ""}
              {specificMentor?.linkedin ? (
                <div
                  className="linkdinBtn"
                  onClick={() =>(specificMentor?.linkedin &&  window.open(specificMentor?.linkedin))
                  }
                >
                  Linkedin
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8125 13.75H2.1875"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.875 2.1875L3.125 10.9375"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.875 8.60625V2.1875H5.45625"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              ) : (
                ""
              )}
            </p>
            <p className="designation">
              {specificMentor?.mentorshipProfession?.designation
                ? `${specificMentor?.mentorshipProfession?.designation} at`
                : ""}{" "}
              {specificMentor?.mentorshipProfession?.company
                ? specificMentor?.mentorshipProfession?.company
                : ""}
            </p>
          </div>
        </div>

        <div className="tabContentOuter mentorRequest mt-30">
          <MentorOverView Details={specificMentor} setisActive={setisActive} />
        </div>
      </div>
    </>
  );
};

export default ViewMentorProfile;
