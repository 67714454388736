import React from 'react'
import './InputFieldStyle.css';

const InputField = ({type,className,placeholder,id,labelName,checkBoxWrap, onChange,disabled, value, checked, isError, erroMessage,notImp, name}) => {
 
  return (
    
    <div className={`formGroups ${checkBoxWrap??""}`}>
        <label htmlFor={id}>{labelName}  
       {!notImp && <span className={"impRed"}>*</span>}
        </label>
        <input type={type} className={className} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} id={id} checked={checked} name={name}/>
        {isError && <span className='FormError'>{erroMessage}</span>}
    </div>
  )
}

export default InputField