import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";


let token;
let adminId;

const superAdminRoute = "/api/mentorship/superAdmin/";
const mentorRoute = "/api/mentorship/mentor/";
const menteeRoute = "/api/mentorship/mentee/";

let setTokenValues = () => {
    token = localStorage.getItem("token");
    adminId = localStorage.getItem("adminId");
};

export const getAllMentors = createAsyncThunk("admin/getAllMentors", async (thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "getAllMentors",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllMentee = createAsyncThunk("admin/getAllMentee", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute + "getAllMentee"
            ,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getSpecificMentor = createAsyncThunk("admin/getSpecificMentor", async (mentorId, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            superAdminRoute +
            "/mentor/" +
            mentorId +
            "/getSpecificMentorDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getMentorInterviewSlots = createAsyncThunk(
    "mentee/getMentorInterviewSlots",
    async ({ date, mentorId }, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(
          environment.baseURL +
            menteeRoute +
            mentorId +
            "/getMentorInterviewSlots?date=" +
            date,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        let data = await response.json();
  
        if (response.status === 200) {
          return data.Data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
 
export const getMenteePastCalls = createAsyncThunk(
    "mentee/getMenteePastCalls",
    async (menteeId, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(
          environment.baseURL +
          superAdminRoute +
          "mentee/"+
            menteeId +
            "/getMenteePastCalls",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        let data = await response.json();
  
        if (response.status === 200) {
          return data.Data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

export const getUpcomingInterviewDetails = createAsyncThunk("mentor/getUpcomingInterviewDetails", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
          superAdminRoute +
          "getAllUpcomingCalls",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getPastInterviewDetails = createAsyncThunk("mentor/getPastInterviewDetails", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
          superAdminRoute +
          "getAllPastCalls",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);  

//router.get("/mentee/:menteeId/getMenteePastCalls",mentorshipSuperAdminController.getMenteePastCalls);
export const getSpecificMentorDetails = createAsyncThunk(
    "mentee/getSpecificMentorDetails",
    async (mentorId, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(
          environment.baseURL +
          superAdminRoute +
          "mentor/"+
            mentorId +
            "/getSpecificMentorDetails",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        let data = await response.json();
  
        if (response.status === 200) {
          return data.Data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const getSpecificMenteeDetails = createAsyncThunk(
    "mentee/getSpecificMenteeDetails",
    async (menteeId, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(
          environment.baseURL +
          superAdminRoute +
          "mentee/"+
            menteeId +
            "/getSpecificMenteeDetails",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        let data = await response.json();
  
        if (response.status === 200) {
          return data.Data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );  


export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        allMentors: [],
        allMentees: [],
        specificMentor: [],
        mentorAvailableSlots: [],
        menteePastCall:[],
        allPastCalls:[],
        allUpcomingCalls:[],
        specificMentorDetails:{},
        specificMenteeDetails:{}
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getAllMentors.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllMentors.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allMentors = payload.Data;
            return state;
        },
        [getAllMentors.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllMentee.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllMentee.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allMentees = payload.Data;
            return state;
        },
        [getAllMentee.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getSpecificMentor.pending]: (state) => {
            state.isFetching = true;
        },
        [getSpecificMentor.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.specificMentor = payload.Data;
            return state;
        },
        [getSpecificMentor.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getMentorInterviewSlots.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.mentorAvailableSlots = payload;
            return state;
          },
          [getMentorInterviewSlots.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
          },
          [getMentorInterviewSlots.pending]: (state) => {
            state.isFetching = true;
          },
          [getMenteePastCalls.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.menteePastCall = payload;
            return state;
          },
          [getMenteePastCalls.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
          },
          [getMenteePastCalls.pending]: (state) => {
            state.isFetching = true;
          },
          [getUpcomingInterviewDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [getUpcomingInterviewDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allUpcomingCalls = payload.Data;
            return state;
        },
        [getUpcomingInterviewDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getPastInterviewDetails.pending]: (state) => {
          state.isFetching = true;
      },
      [getPastInterviewDetails.fulfilled]: (state, { payload }) => {
          state.isFetching = false;
          state.allPastCalls = payload.Data;
          return state;
      },
      [getPastInterviewDetails.rejected]: (state, { payload }) => {
          state.isFetching = false;
          state.isError = true;
          state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getSpecificMentorDetails.pending]: (state) => {
        state.isFetching = true;
    },
    [getSpecificMentorDetails.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.specificMentorDetails = payload;
        return state;
    },
    [getSpecificMentorDetails.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificMenteeDetails.pending]: (state) => {
      state.isFetching = true;
  },
  [getSpecificMenteeDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.specificMenteeDetails = payload;
      return state;
  },
  [getSpecificMenteeDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
  }
      
      

        
    }
});



export const { clearState } = adminSlice.actions;

export const adminSliceData = (state) => state.admin;