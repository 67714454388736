import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

const mentorRoute = "/api/mentorship/mentor/";

export const loginUser = createAsyncThunk(
  "student/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };

      console.log("nvnfjkvnfknvjfnfv fv f", payload);
      const response = await fetch(
        `${environment.baseURL}${mentorRoute}login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        const type = data.Token.split(" ")[0]
        console.log(type,"oops")
        if(type==="SuperAdmin"){
          localStorage.setItem("user","admin");
          localStorage.setItem("adminId",data.Data.id);
          localStorage.setItem("name", data.Data.username);
          localStorage.setItem("profileImg", data.Data.profilePicUrl);
        }else{
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("user","mentor");
          localStorage.setItem("name", data.Data.firstName + " " + data.Data.lastName);
          localStorage.setItem("mentorId", data.Data.id);
          localStorage.setItem("profileImg", data.Data.profilePicUrl);
        } 
        localStorage.setItem("token", data.Token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "studentLogin",
  initialState: {
    username: "",
    email: "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.token = payload?.Token
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUser.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;