import React from 'react'
import './HeaderStyle.css'
const Header = () => {
  const pathname = window.location.pathname;
  return (
    <header className="headerMenu">
        <div className="headerContainer">
            <img src="../assets/icons/mastersUnionBlack.svg" alt="" className='logo' />
        </div>
    </header>
  )
}

export default Header