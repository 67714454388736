import React from 'react'
import './SlotSelectorStyle.css';

const SlotSelector = ({id,name,value,text,checked,disabled}) => {
  return (
    <div className="slotWrapper">
        <input type="radio" checked={checked} disabled={disabled} id={id} name={name} value={value}/>
        <label htmlFor={id} disabled={disabled}>
            {text}
        </label>
    </div>
  )
}

export default SlotSelector