import React, { useState, useEffect } from "react";
import { Calendar } from "react-calendar";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import SlotSelector from "../../../components/SlotSelector/SlotSelector";
import "./MentorSlotsStyle.css";
import {
  getMentorInterviewSlots,
  clearState,
  mentorSliceData,
} from "../../../reducer/mentorSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import PageLoader from "../../../components/PageLoader/PageLoader";

const MentorSlots = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { availableSlots, isFetching, isError } = useSelector(mentorSliceData);

  //Toast states
  const [errMessage, seterrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    dispatch(getMentorInterviewSlots(moment(date).format("YYYY-MM-DD")));
  }, [date]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="headerSmall withButton">
        <p className="HighlightHeading">My Profile</p>
        <Button
          className={"imgButton GreenSlotBorder"}
          src={"/assets/icons/calendar.png"}
          name={"Edit Slots"}
          onClick={() => navigate("/editMentorSlots")}
        />
      </div>

      <div className="dateRangeWrapper mt-10">
        <div className="popupBody">
          <div className="schedulerContainer">
            <div className="calendarOuter">
              <Calendar
                minDate={new Date()}
                value={date}
                onChange={(e) => setDate(e)}
              />
            </div>
            <div className="selectInterviewContainer">
              <p className="month">Slot Timing</p>
              <div className="slotsOuter">
                {availableSlots?.length > 0
                  ? availableSlots?.map((item, index) => (
                      <SlotSelector
                        text={item.time}
                        id={"slot" + index}
                        name={"slotter"}
                        value={"value"}
                        disabled
                      />
                    ))
                  : "No slot added"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorSlots;
