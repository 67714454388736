import React, { useState,useEffect } from 'react'
import './EditProfileMentorStyle.css';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import * as filestack from "filestack-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../../components/Toast/ErrorToast"
import {getMentorDetails, mentorSliceData, saveMentorDetails, clearState} from "../../../reducer/mentorSlice";
import PageLoader from "../../../components/PageLoader/PageLoader";
import UploadImagePopup from "../../../components/uploadImgPopup/UploadImagePopup"


const EditProfileMentor = () => {
  const client = filestack.init("AcpQ2RjKSnWT5GjS9f6wOz");
  const token = {};
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  
  const { specificMentor, isFetching, isSuccess } =
    useSelector(mentorSliceData);
   

  let profileDetail = {
    linkedin: "",
    mobileNumber: "",
    companyName: "",
    designation: "",
    yrsOfExp: "",
    interest: [],
    topics:[],
  };

  const intrestArr =[
    "Finance & Accounting",
    "Consulting",
    "Research",
    "Investment Banking",
    "Product Management",
    "Marketing",
    "Banking & Insurance",
    "E-Learning",
    "Technology",
    "UX/UI",
    "Sales",
    "Operations",
    "Human Resources",
    "Digital Transformation",
    "Entrepreneurship",
    "Customer Experience",
    "Data Science",
    "Cloud/Cyber-security",
    "IT Consulting",
    "PR",
    "Corporate",
    "Communication",
    "Startup",
    "Project Management",
    "Machine learning",
    "Supply chain",
    "FP&A",
    "Insurance"
  ]

  const topicArr = [
    "Career Advice",
    "Job Search",
    "Skill Development",
    "Higher Education",
    "Career Transition",
  ]

  const [profileData, setProfileData] = useState({ ...profileDetail });
  // const[popUpActive,setPopUpActive]=useState(false);
 console.log(profileData, "121324343");
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [selectedProfileKey, setSelectedProfileKey] = useState("");
  const [urlProfile, setUrlProfile] = useState(null);
  const [url, setUrl] = useState("");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [load, setLoad] = useState(false);
  const [active, setActive] = useState(false);
 
  const profileDataOnChange = (e) => {
    const { name, value } = e.target;
   if(name!=="topics" && name!=="interest"){
     setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
     
  
  }
   else{
    if(profileData[name].includes(value)) {
      setProfileData(prev => ({
        ...prev,
        [name]: prev[name].filter((item) => item !== value)
      }));
    }
    
    else {
      if(profileData[name].length === 3) {
        return;
      }
      else{
      setProfileData((prevState) => ({
        ...prevState,
        [name]: [...prevState[name],value],
      }));
    }
  }
  };

   }

   const handleSubmit = () => {
    const obj = {
      linkedin: profileData?.linkedin,
      mobileNumber: profileData?.mobileNumber,
      interest: profileData?.interest,
      topics: profileData?.topics,
      companyName: profileData.companyName,
      designation: profileData.designation,
      experience: profileData.yrsOfExp,
      profilePicUrl: urlProfile,

      
    };
    let status = true;
    for (const key in obj) {
      if (key === "linkedin" || key === "profilePicUrl" || key ==="mobileNumber") continue;
      if (!obj[key]) {
        console.log(key)
        status = false;
        setIsShowError(true);
        setErrMessage("Please fill all fields!");
        return;
      }
    }
    if (status) dispatch(saveMentorDetails(obj));
    else {
      setIsShowError(true);
      setErrMessage("Please fill all fields!");
    }
  }; 

// const obj= {
//   linkedin: profileData.linkedin,
//     mobileNumber: profileData.mobileNumber,
//     companyName: profileData.companyName,
//     designation: profileData.designation,
//     experience: profileData.experience,
//     interestArea:interestArea,
//     topic:topic
// }

useEffect(()=>{
  dispatch(getMentorDetails())
},[])

useEffect(() => {
  setProfileData((prevState) => ({
    ...prevState,
    linkedin: specificMentor?.linkedin,
    mobileNumber: specificMentor?.mobileNumber,
    companyName: specificMentor?.mentorshipProfession?.company,
    designation: specificMentor?.mentorshipProfession?.designation,
    yrsOfExp: specificMentor?.mentorshipProfession?.yrsOfExp,
    interest: specificMentor?.mentorshipInterests?.map(res=>res.interest),
    topics: specificMentor?.mentorshipTopics?.map(res=>res.Topic)
  }));
  setUrlProfile(specificMentor?.profileImg);

}, [specificMentor]);
// console.log(profileData);

const handleUploadProfile = (event) => {
  console.log(event.target.files[0].type);
  if (
    event.target.files.length > 0
  ) {
    const files = event.target.files[0];
    setLoad(true);
    client
      .upload(files, {}, token)
      .then((res) => {
        if (res) {
          setSelectedProfileKey(res.name);
          setUrl(res.url);
          setLoad(false);
          setActive(true);
          console.log("resulttt", active);
          // setUploadPopUp(false)
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
        alert("image are not supported");
        // setIsShowError(true);
        // setErrMessage(`${err.status}!`);
        // setErrMessage("All fields are mandatory")
      });
  }
};

const clearProfile = () => {
  setUrl(null);
  setSelectedProfileKey(null);
};

const ClearhandleRemove = () => {
  setUrlProfile(null);
  setUploadPopUp(false);
};

useEffect(() => {
  if (isSuccess) {
    dispatch(clearState());
    setProfileData({ ...profileDetail });
    localStorage.setItem("profileImg", urlProfile);
    Navigate("/viewMentor", { exact: true });
  }
  // eslint-disable-next-line
}, [isSuccess]);

  return (
    <>
    {(isFetching || load) && <PageLoader />}
    <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
     <UploadImagePopup
            active={active}
            popout={uploadPopUp}
            handleClose={() => {
              setSelectedProfileKey(null);
              setUploadPopUp(false);
              if (active) {
                setUrlProfile(url);
              }
            }}
            handleUploadProfile={handleUploadProfile}
            value={selectedProfileKey}
            handleRemove={clearProfile}
            handleDelete={() => {
              if (selectedProfileKey !== "") {
                setActive(false);
              } else {
                setActive(true);
              }
            }}
            clearRemoveProfile={ClearhandleRemove}
          /> 
      <div className="editWrapper">
        <div className="headerSmall withButton">
          <p className="HighlightHeading"><span className="parent" onClick={() => Navigate("/viewMentor")}>My Profile </span>/ Edit Profile</p>
          <Button className={'imgButton GreenSlotBorder'} src={'/assets/icons/tick-circle.png'} name={'Save Changes'} onClick={()=>{handleSubmit()}}/>
        </div>

        <div className="editProfileWrapper mt-10">
          <div className="mentorProfile profileMentor">
            <div className="profileImageWrap">
            <img
                src={urlProfile
                  ? urlProfile
                  :"https://events.powerathletehq.com/wp-content/uploads/2018/06/grey-person-coming-soon-e1529779699986.jpg"} alt="" className="mentorImg"/>
              <img
                src="/assets/icons/editProfile.png"
                className="editIconProfile"
                onClick={() => setUploadPopUp(true)}
              />
            </div>
            
            <div className="mentorDetails">
              <p className="mentorName">{specificMentor?.firstName}{specificMentor?.lastName ? specificMentor?.lastName : ""}</p>
                <p className="designation">{specificMentor?.designation ? specificMentor?.designation : "" }</p>
            </div>
            
          </div>

          <div className="profileDetails mt-40">
            {/* <InputField
              type={"email"}
              className={"FormInput mb-30"}
              placeholder={"user@gmail.com"}
              id={"name"}
              labelName={"Email"}
            /> */}

            <InputField
              type={"text"}
              name="linkedin"
              value={profileData.linkedin}
              className={"FormInput mb-30"}
              placeholder={"linkedin.com/user"}
              id={"linkedinProfile"}
              labelName={"LinkedIn Profile"}
              onChange={profileDataOnChange}
            />

            <InputField
              type={"email"}
              name="mobileNumber"
              value={profileData?.mobileNumber}
              className={"FormInput"}
              placeholder={"9876543210"}
              id={"name"}
              labelName={"Contact Number"}
              onChange={profileDataOnChange}
            />
            <small className="smallNotification mt-10 mb-30">
              Contact number won’t be visible on your public profile
            </small>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel labelExtra">
                Key areas of professional interest or domain of expertise [maximum-3]<span className="impRed">*</span> 
              </label>
              <div className="feedbackRadioGroup">
              {intrestArr?.map((res, i)=>(
                   <div className="radioBtnOuter">
                    <input
                    value={res}
                      type="checkbox"
                      id={"i" + i}
                      name="interest"
                      checked={profileData?.interest?.length>0 && profileData?.interest.includes(res)}
                      onChange={profileDataOnChange}
                    />
                    <label for={"i" + i}>{res}</label>
                  </div>))}
                  {/* <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test`}
                      name="radio-group"
                    />
                    <label htmlFor={`test`}>FP&A</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test`}
                      name="radio-group"
                    />
                    <label htmlFor={`test`}>Actuary</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test`}
                      name="radio-group"
                    />
                    <label htmlFor={`test`}>Others</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test`}
                      name="radio-group"
                    />
                    <label htmlFor={`test`}>Insurance</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={`test`}
                      name="radio-group"
                    />
                    <label htmlFor={`test`}>Investment Banking</label>
                  </div> */}
              </div>
            </div>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel labelExtra">
                Mentorship topic [maximum-3]<span className="impRed">*</span>
              </label>
              <div className="feedbackRadioGroup">
              {topicArr.map((res,i)=>(
                   <div className="radioBtnOuter">
                    <input
                     value={res}
                      type="checkbox"
                      id={"m" + i}
                      name="topics"
                      checked={profileData?.topics?.length>0 && profileData?.topics.includes(res)}
                      onChange={profileDataOnChange}
                    />
                    <label for={"m" + i}>{res}</label>
                  </div>))} 
                  {/* <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={"test1"}
                      name="radio-group"
                    />
                    <label htmlFor="test1">Skill Development</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={"test1"}
                      name="radio-group"
                    />
                    <label htmlFor="test1">Higher Education</label>
                  </div>
                  <div className="radioBtnOuter">
                    <input
                      type="checkbox"
                      id={"test1"}
                      name="radio-group"
                    />
                    <label htmlFor="test1">Career Transition</label>
                  </div> */}
              </div>
            </div>
              <div className="editProfileDetailNew">
                <InputField
                  type={"text"}
                  name="companyName"
                  value={profileData?.companyName}
                  className={"FormInput mb-30"}
                  placeholder={"Enter Company name"}
                  id={"companyName"}
                  labelName={"Company"}
                  onChange={profileDataOnChange}
                />

                <InputField
                  type={"text"}
                  name="designation"
                  className={"FormInput mb-30"}
                  value={profileData?.designation}
                  placeholder={"Enter Designation"}
                  id={"Designation"}
                  labelName={"Designation"}
                  onChange={profileDataOnChange}
                />

                <InputField
                  type={"email"}
                  name="yrsOfExp"
                  value={profileData?.yrsOfExp}
                  className={"FormInput"}
                  placeholder={"Years of Experience"}
                  id={"name"}
                  labelName={"Years of Experience"}
                  onChange={profileDataOnChange}
                />
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProfileMentor