import React from "react";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import "./ChangePassStyle.css";

const ChangePassPopup = ({
    isActive,
    value,
    onChange,
    onClick,
    validation,
    handleClose,
    onKeyPress
}) => {
    console.log(validation.error,"xoxox")
    return (
        <>
            <div className={`popupOuter ${isActive ? "active" : ""}`}>
                <div className={`changePassPopupContainer `}>
                    <div className="popupBody">
                        <div class="successContainer">
                            <div class="successContainerBody">
                                <div className="changePassHeadingContainer">
                                    <h2>Change Password</h2>
                                    <img
                                        src="/assets/icons/Close.svg"
                                        className="pointer"
                                        alt=""
                                        onClick={handleClose}
                                    />
                                </div>
                                <div className="changePassInputContainer">
                                    <InputField labelName={"Old Password"} placeholder={"******"} onKeyPress={onKeyPress} onChange={onChange} value={value.oldPassword} name={"oldPassword"} isError={validation.oldPassword} erroMessage={validation.oldPassword} />
                                    <InputField labelName={"New Password"} placeholder={"******"} onKeyPress={onKeyPress} onChange={onChange} value={value.newPassword} name={"newPassword"} isError={validation.newPassword} erroMessage={validation.newPassword} />
                                    <InputField labelName={"Confirm Password"} placeholder={"******"} onKeyPress={onKeyPress} onChange={onChange} value={value.confirmPassword} name={"confirmPassword"} isError={validation.confirmPassword} erroMessage={validation.confirmPassword} />
                                </div>
                                {validation.error && <div className="flexRow">
                                    <p className="changePassError">{validation.error}</p>
                                </div>}
                                <div className="changePassButtonContainer">
                                    <Button name={"Update"} className="changePassButton" onClick={onClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassPopup;
