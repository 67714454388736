import React from "react";
import './customUploadBoxStyle.css';

const CustomUploadBox = ({ label,onChange ,value,handleRemove,accept,newValue ,isImportant, uid,active, handleDelete}) => {

    return (
        <div className="uploadBoxOuter">
            <p className="label">{label} {isImportant? <span className='imgType'>[Formate-jpeg/png]</span>:null }{isImportant? <span className='impMark'>*</span>:null }</p>
            <div className="upload-box-inner">
                <label htmlFor={uid}>
                    <span className='upload-btn'>Choose File</span>
                   {!value? "No File Chosen":value }
                </label>
               {!value? <input type="file" id={uid} value={newValue} onChange={onChange} accept={accept} />: "" }

                <div class={`upload-box-icons ${active?"active":""}`} onClick={ handleDelete} >
                    <img src="/assets/icons/trash.png" alt="" onClick={handleRemove}/>
                    {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <circle cx="15" cy="15" r="15" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 9.1875C15.3107 9.1875 15.5625 9.43934 15.5625 9.75V14.4375H20.25C20.5607 14.4375 20.8125 14.6893 20.8125 15C20.8125 15.3107 20.5607 15.5625 20.25 15.5625H15.5625V20.25C15.5625 20.5607 15.3107 20.8125 15 20.8125C14.6893 20.8125 14.4375 20.5607 14.4375 20.25V15.5625H9.75C9.43934 15.5625 9.1875 15.3107 9.1875 15C9.1875 14.6893 9.43934 14.4375 9.75 14.4375H14.4375V9.75C14.4375 9.43934 14.6893 9.1875 15 9.1875Z" fill="#262626" />
                    </svg> */}

                </div>
            </div>
        </div>
    )
}

export default CustomUploadBox