import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AllMentor from './Pages/Admin/allMentor/AllMentor';
import Header from './components/header/Header';
import SideMenu from './components/SideMenu/SideMenu';
import ViewProfile from './Pages/Admin/ViewProfile/ViewProfile';
import AllStudents from './Pages/Admin/allStudents/AllStudents';
import ViewProfileStudent from './Pages/Admin/ViewProfileStudent/ViewProfileStudent';
import AllCalls from './Pages/Admin/AllCalls/AllCalls';
import ViewMentorProfile from './Pages/Mentor/MentorsProfile/ViewMentorProfile';
import EditProfileMentor from './Pages/Mentor/EditProfileMentor/EditProfileMentor';
import MentorSlots from './Pages/Mentor/MentorSlots/MentorSlots';
import EditSlots from './Pages/Mentor/MentorSlots/EditSlots';
import AllMentorCalls from './Pages/Mentor/AllMentorCalls/AllMentorCalls';
import MentorFeedback from './Pages/Mentor/MentorFeedback/MentorFeedback';
import MentorFeedbackPreview from './Pages/Mentor/MentorFeedback/MentorFeedbackPreview';
import FeedbackPreviewCall from './Pages/Admin/Feedback/FeedbackPreviewCall';
import LoginPage from './Pages/authentication/LoginPage';
import { useSelector } from 'react-redux';
import { userSelector } from './reducer/userSlice';
import { PrivateRoute } from './helpers/PrivateRoute';

function App() {

  const pathname = window.location.pathname;
  const { token } = useSelector(userSelector);
  return (
    <>
      {/* {(pathname !== "/login" && token !== null) && <Header />} */}
      <Header />
      <div className='appContainer'>
        <BrowserRouter>
          {(pathname !== "/login" && token !== null) && <SideMenu />}
          <div className="sectionAdjust">
            <Routes>
              <Route path='/login' exact element={<LoginPage />} />
              <Route path='/' exact element={<PrivateRoute />}>

                {/* <Route path="/" exact element={<AllMentor />} />
                <Route path="/viewProfile/:id" exact element={<ViewProfile />} />
                <Route path="/allStudent" exact element={<AllStudents />} />
                <Route path="/allMentor" exact element={<AllMentor/>} />
                <Route path="/allCalls" exact element={<AllCalls />} />
              <Route path="/FeedbackPreviewCall" exact element={<FeedbackPreviewCall />} /> */}

                {/* mentor routes*/}
                <Route path="/" exact element={<AllMentorCalls />} />
                <Route path="/viewMentor" exact element={<ViewMentorProfile />} />
              <Route path="/viewStudent/:id" exact element={<ViewProfileStudent />} />
                <Route path="/editMentor" exact element={<EditProfileMentor />} />
                <Route path="/mentorSlots" exact element={<MentorSlots />} />
                <Route path="/editMentorSlots" exact element={<EditSlots />} />
                <Route path="/mentorFeedback" exact element={<MentorFeedback />} />
              <Route path="/mentorFeedbackPreview" exact element={<MentorFeedbackPreview />} />

              </Route>
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
